.container-login {
    width: 200px;
    margin: 100px auto 0;
    text-align: left;
}

#username {
    width: 200px;
}

#password {
    width: 200px;
}

#ID_Login {
    font-size: 14px;
    background: #3d9be8;
    color: #fff;
    padding: 5px 20px;
    margin: 50px auto 0;
    border-radius: 10px;
    width: 100px;
    text-decoration: none;
    display: block;
}

.message.error {
    margin-bottom: 20px;
    color: red;
}
